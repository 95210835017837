import { computed } from 'vue';
import NoSleep from 'nosleep.js';
import io from 'socket.io-client';
import { MessageBox } from 'element-ui';
import Manifest from '~manifest';
import { getMobile, isSafari } from '../../utils';

const noSleep = new NoSleep();
const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

export default {
  inject: ['moment'],
  provide() {
    return {
      getVersion: () => {
        this.getVersion();
      },
      BGMPlay: () => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.play();
      },
      BGMPause: () => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.pause();
      },
      checkUserNotificationIsEnabled: () => {
        this.checkUserNotificationIsEnabled();
      },
      runWebSocket: () => this.runWebSocket(),
      closeSocket: () => {
        this.closeSocket();
      },
      playSound(type = 'open') {
        if (!this.$store.state.app.isBGMEnable) return;
        window.createjs.Sound.play(type);
      },
      isYoutubePlayerShow: computed(() => this.isYoutubePlayerShow),
      isRotate: computed(() => this.isRotate),
      isSafari: isSafari()
    };
  },
  data() {
    return {
      appName: process.env.VUE_APP_TITLE,
      isDomainLoaded: false,
      prompt: false,
      isRotate: false,
      loadedCount: 0,
      deferredPrompt: null,
      isPromptShow: false,
      isSafariHintShow: false,
      isSafari: iOSSafari,
      isYoutubePlayerShow: true,
      isYoutubePlayerLoaded: false,
      isIosTutorialShow: false,
      defaultViewport: {
        width: 0,
        height: 0
      },
      WebSocket: {
        HeartsInterval: null
      },
      isGotVersion: false,
      isQueryChecked: false,
      isSubscribeLoading: false,
      timer: null,
      hintTimer: null,
      hintCountDown: 4,
      isContentLoaded: false,
      barWidth: 0,
      currentProgress: 0,
      progressInterval: null,
      step: 0.1
    };
  },
  watch: {
    $route(newVal, oldVal) {
      this.windowResize();
    },
    language: {
      immediate: true,
      handler(val) {
        if (!val) return;
        const classList = document.querySelector('html').classList;
        classList.remove('th');
        classList.remove('en');
        classList.remove('zh-CN');
        classList.add(val);
      }
    },
    token: {
      immediate: true,
      handler(val) {
        if (val && !this.$socket.connected) {
          this.runWebSocket();
        } else if (!val && this.$socket.connect) {
          this.closeSocket();
        }

        this.fetchData();
      }
    },
    userInfo: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.Account === oldVal?.Account && newVal.FBID === oldVal?.FBID) return;
        if (newVal.Account || newVal.FBID) {
          window.dataLayer.push({ ga_c_id: newVal.Account || newVal.FBID });
        }
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    token() {
      return this.$store.getters.Token;
    },
    isRotateShow() {
      return this.$store.getters.isOpenGameWallet
        ? this.isRotate
        : this.isRotate && !this.$store.getters.isGameView;
    },
    userInfo() {
      return this.$store.getters.MemberInfo;
    },
    localTime() {
      const BaseTime = this.moment.utc('2021-01-01 00:01'); // UTC+0時間基準點 日期隨便設定 主要是後面的時間
      const localTime = this.moment(BaseTime).local().format('HH:mm'); // 轉成當地時間

      return localTime;
    }
  },
  async created() {
    await this.$store.dispatch('app/setIsAlertShow', false);
    const qs = new URLSearchParams(window.location.search);
    const ac = qs.get('ac');
    if (ac) {
      const AgentCode = ac;
      if (ac !== this.$store.getters.AgentCode) {
        await this.$store.dispatch('user/setAgentCode', AgentCode);
        await this.$store.dispatch('user/setToken', '');
        // 清空 Device
        await this.$store.dispatch('user/setDevice', '');
        // 清空 MemberInfo
        await this.$store.dispatch('user/setMemberInfo', {});
        // 清掉 Vuex FBInfo
        await this.$store.dispatch('user/setFBInfo', {});
        // 清掉 暫存的手機號碼
        await this.$store.dispatch('timer/setTimerTempData', null);
      } else if (this.$store.getters.Token) {
        this.$router.replace('/');
      }
    }
    const MaintainState = await this.checkMaintain();
    if (MaintainState) {
      this.isGotVersion = await this.getVersion();
    }
    await this.checkQuery();
    await this.createManifest();
    await this.$store.dispatch('app/setGameView', false);
    this.windowResize();
    window.addEventListener('load', this.windowLoading);
    window.addEventListener('resize', this.windowResize);
    window.addEventListener('orientationchange', this.windowResize);
    window.addEventListener('gesturestart', (event) => {
      event.preventDefault();
    });
    window.addEventListener('touchstart', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    let lastTouchEnd = 0;
    window.addEventListener(
      'touchend',
      (event) => {
        let now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    this.enableNoSleep();
    window.addEventListener('blur', () => {
      this.$nextTick(() => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.pause();
      });
    });
    window.addEventListener('focus', () => {
      this.windowResize();
      if (this.$store.state.app.isBGMEnable && !this.$store.getters.isGameView) {
        this.$nextTick(() => {
          this.$refs.Mp3Player && this.$refs.Mp3Player.play();
        });
      }
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      if (e.prompt) {
        document.querySelector('#app').addEventListener('click', this.handleTriggerPrompt);
      }
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
      this.isPromptShow = false;
    });
    window.OneSignal = window.OneSignal || [];
    window.createjs.Sound.registerSound(require('@/assets/music/open.wav'), 'open');
    window.createjs.Sound.registerSound(require('@/assets/music/close.wav'), 'close');
    window.createjs.Sound.registerSound(require('@/assets/music/take-reward.mp3'), 'takeReward');
  },
  beforeDestroy() {
    window.removeEventListener('load', this.windowLoading);
    window.removeEventListener('resize', this.windowResize);
    window.removeEventListener('orientationchange', this.windowResize);
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.hintTimer) {
      clearInterval(this.hintTimer);
    }
  },
  async mounted() {
    this.$store.dispatch('user/setAgentCode', 'benAgent01');
    this.$store.dispatch('initData/getInitData');
    this.progressInterval = setInterval(() => {
      this.currentProgress += this.step;
      const progress = Math.round(Math.atan(this.currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000;
      this.barWidth = progress;
      if (progress >= 100) {
        clearInterval(this.progressInterval);
        this.isContentLoaded = true;
      } else if (progress >= 70) {
        this.step = 0.01;
      }
    }, 1000);
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.barWidth = 100;
        clearInterval(this.progressInterval);
        setTimeout(() => {
          this.isContentLoaded = true;
        }, 1000);
      }
    };
    setTimeout(() => {
      if (!this.isContentLoaded) {
        this.barWidth = 100;
        clearInterval(this.progressInterval);
        this.isContentLoaded = true;
      }
    }, 20000);
    this.$store.commit('app/SET_ISLOADING', false);
    this.$store.dispatch('app/setGameFilter', {
      gameType: null,
      provider: 'top50',
      providerId: null
    });
    // OneSignal
    if (process.env.VUE_APP_ENV !== 'local') {
      this.getOneSignal();
    }
    if (this.isSafari && !window.navigator.standalone) {
      this.isSafariHintShow = true;
      this.$nextTick(() => {
        this.$refs.IOSVideo.onloadeddata = () => {
          this.isYoutubePlayerLoaded = true;
        };
      });
    } else {
      this.isYoutubePlayerShow = false;
    }
    this.checkCrossDay();
    this.startHintTimer();
  },
  methods: {
    async getVersion() {
      // await API.Version.Check({}).then(res => {
      //   const { Data } = res
      //   console.log('當前版本:', Data.Version)
      //   this.$store.dispatch('app/setVersion', Data.Version)
      // })
      return Promise.resolve(true);
    },
    windowLoading(e) {
      this.windowResize(e);
    },
    async windowResize(e) {
      // 單純旋轉螢幕紀錄原始寬高
      if (this.defaultViewport.width === 0 || (window.innerWidth + window.innerHeight) === (this.defaultViewport.width + this.defaultViewport.height)) {
        this.defaultViewport.width = window.innerWidth;
        this.defaultViewport.height = window.innerHeight;
      }
      if (window.innerWidth < window.innerHeight || window.orientation === 0) {
        this.isRotate = true;
      } else {
        this.isRotate = false;
      }

      // 為了 Android 鍵盤高度會擠壓 innerheight
      if (this.defaultViewport.width === window.innerWidth && this.defaultViewport.height !== window.innerHeight) return;
      setTimeout(() => {
        const windowsVH = window.innerHeight / 100;
        document.documentElement.style.setProperty('--vh', `${windowsVH}px`);
      }, 100);
    },
    faceBookLogout() {
      // 檢查登入狀態
      window.FB.getLoginStatus((response) => {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB.logout((response) => {
            console.log(response);
          });
          // })
        } else {
          // do something
          console.log('logout connected else something');
        }
      });
    },
    getOneSignal() {
      let self = this;
      window.OneSignal.push(() => {
        /* These examples are all valid */
        let isPushSupported = window.OneSignal.isPushNotificationsSupported();
        if (isPushSupported) {
          // Push notifications are supported
          // 瀏覽器支援 初始化OneSignal
          // 測試要注意 一定要用手機開 瀏覽器手機模式沒用
          console.log('Push notifications are supported');
          let device = getMobile();
          if (device === 'iPhone' || device === 'iPad' || device === 'iPod') return;
          window.OneSignal.init({
            appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
            safari_web_id: process.env.VUE_APP_ONE_SIGNAL_SAFARI_WEB_ID,
            autoResubscribe: true,
            notifyButton: {
              enable: true
            }
          });
        }
      });
      if (self.$store.getters.AgentCode) {
        this.checkUserNotificationIsEnabled();
      }
      window.OneSignal.on('subscriptionChange', (isSubscribed) => {
        console.log('The user\'s subscription state is now:', isSubscribed);
        // 允許推播
        if (self.$store.getters.AgentCode) {
          if (isSubscribed) {
            // console.log('用戶允許')
            window.OneSignal.getUserId().then((res) => {
              // console.log('取得推播ID', res)
              // 防止1秒內的連打
              if (self.isSubscribeLoading) {
                console.log('訂閱裝置碼讀取中, 已擋下');
                return;
              }
              self.isSubscribeLoading = true;
              let formData = {
                Provider: 'OneSignal',
                APPID: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
                PlayerID: res
              };
              // console.log('儲存推播裝置碼 FormData===>', formData)
              // var customRequest = self.$store.getters.Token ? API.Notification.SubscribeHasLogin : API.Notification.SubscribeNoLogin
              // customRequest(formData).then(res => {
              //   self.isSubscribeLoading = false
              //   // console.log('儲存推播裝置碼', res)
              // }).catch(() => {
              //   self.isSubscribeLoading = false
              //   console.log('error')
              // })
            });
          } else {
            // 否則
            // console.log('用戶拒絕')
          }
        }
      });
    },
    checkUserNotificationIsEnabled() {
      let self = this;
      window.OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
        if (isEnabled) {
          console.log('Push notifications are enabled!');
          window.OneSignal.push(['getNotificationPermission',
            function (permission) {
            // console.log('Site Notification Permission:', permission)
            // (Output) Site Notification Permission: default
              switch (permission) {
                case 'granted': // 用戶本來就同意推播
                  window.OneSignal.getUserId().then((res) => {
                  // console.log('取得推播ID', res)
                    if (self.isSubscribeLoading) {
                      console.log('訂閱裝置碼讀取中, 已擋下');
                      return;
                    }
                    self.isSubscribeLoading = true;
                    let formData = {
                      Provider: 'OneSignal',
                      APPID: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
                      PlayerID: res
                    };
                  // console.log('儲存推播裝置碼 FormData===>', formData)
                  // var customRequest = self.$store.getters.Token ? API.Notification.SubscribeHasLogin : API.Notification.SubscribeNoLogin
                  // customRequest(formData).then(res => {
                  //   self.isSubscribeLoading = false
                  //   // console.log('儲存推播裝置碼', res)
                  // }).catch(() => {
                  //   self.isSubscribeLoading = false
                  //   console.log('error')
                  // })
                  });
                  break;
                case 'denied': // 用戶本身就拒絕了
                  window.OneSignal.showSlidedownPrompt();
                  break;
                default: // 用戶設定是預設詢問
                  window.OneSignal.showSlidedownPrompt();
                  break;
              }
            }]);
        } else {
          console.log('Push notifications are not enabled yet.');
          window.OneSignal.showSlidedownPrompt();
        }
      });
    },
    handleLoaded() {
      this.$nextTick(() => {
        this.$store.commit('app/SET_ISLOADING', false);
      });
    },
    async checkQuery() {
      // query 有 ac 則檢查正確並儲存, 如果是推薦人連結不做換頁
      // const qs = new URLSearchParams(window.location.search)
      // const AgentCode = qs.get('ac')
      // if (AgentCode) {
      //   var formData = {
      //     AgentCode
      //   }
      //   API.System.CheckAgentCode(formData).then(async res => {
      //     if (res.ErrorCode === 0) {
      //       await this.$store.dispatch('user/setAgentCode', AgentCode)
      //       await this.$store.dispatch('app/setLanguage', res.Data.LanguageCode)
      //       await this.$store.dispatch('app/setLocationCode', res.Data.LocationCode.toLowerCase())
      //       await this.$store.dispatch('app/setLocationID', res.Data.LocationID)
      //       if (this.$i18n.locale !== res.Data.LanguageCode) {
      //         this.$i18n.locale = res.Data.LanguageCode
      //       }
      //       this.isQueryChecked = true
      //       if (qs.cmd === 'referrer') return
      //       this.$router.replace('/')
      //     } else {
      //       await this.$store.dispatch('user/setAgentCode', '')
      //       this.isQueryChecked = true
      //       this.$router.replace('/')
      //     }
      //   }).catch((err) => {
      //     console.log('error' + err)
      //   })
      // } else {
      //   this.isQueryChecked = true
      // }
      this.isQueryChecked = true;
    },
    enableNoSleep() {
      document.addEventListener('click',
        function enableNoSleep() {
          noSleep.enable();
          document.removeEventListener('click', enableNoSleep, false);
        },
        false);
      document.addEventListener('touchstart',
        function enableNoSleep() {
          noSleep.enable();
          document.removeEventListener('touchstart', enableNoSleep, false);
        },
        false);
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.isPromptShow = false;
    },
    async install() {
      this.$track('點擊Android安裝PWA按鈕', {
        event_category: 'click',
        event_label: '點擊Android安裝PWA按鈕'
      });
      this.deferredPrompt.prompt();
    },
    handleDrawerClose() {
      this.dismiss();
    },
    handleSafariDrawerClose() {
      this.isSafariHintShow = false;
    },
    handleTriggerPrompt(e) {
      if (this.deferredPrompt) {
        this.isPromptShow = true;
        document.querySelector('#app').removeEventListener('click', this.handleTriggerPrompt);
      }
    },
    runWebSocket() {
      if (this.$socket && this.$socket.connected) {
        return;
      }
      const uri = `${this.$store.getters.Domains?.SocketServer}/v1/frontend`;
      const SocketManagerOptions = {
        // path: 'socket.io', // string 在服務器端捕獲的路徑的名稱
        reconnection: true, // boolean 是否自動重新連接
        // reconnectionAttempts: 10, // number 放棄之前重新連接嘗試的次數
        reconnectionDelay: 1500, // number 嘗試重新連接（1000）之前最初等待的時間。受+/-影響randomizationFactor，例如，默認的初始延遲將在500到1500ms之間
        randomizationFactor: 0.5, // number  0 <= randomizationFactor <= 1
        reconnectionDelayMax: 5000, // number 兩次重新連接之間要等待的最長時間（5000）。每次嘗試都會使重新連接延遲增加2倍，並如上所述進行隨機分配
        timeout: 180000, // number 發出connect_error 和connect_timeout事件之前的（Number）連接超時（20000）
        autoConnect: false, // boolean 將此值設置為false，則您必須manager.open 在確定合適的時間進行調用
        withCredentials: false,
        auth: {
          env: process.env.VUE_APP_SOCKET_ENV,
          source: process.env.VUE_APP_KEYWORD,
          tn: this.$store.getters.Token
        }
        // query: '',// 連接名稱空間時發送的其他查詢參數（然後在socket.handshake.query服務器端的對像中找到）
        // parser: '' // 要使用的解析器。默認為Parsersocket.io隨附的的實例。參見socket.io-parser。
      };
      this.$socket = io(uri, SocketManagerOptions);
      // Socket連線 之後 五秒沒打 login 會斷線
      this.$socket.connect();
      this.$socket.on('connect', () => {
        console.log('Socket connected');
        this.$socket.emit('cmd', {
          time: Number(this.moment().format('x')),
          action: 'check',
          channel: 'version',
          data: {
            version: this.$store.getters.SocketVersion
          },
          extension: {},
          page: { pageIndex: 0, pageSize: 0 }
        });
      });

      this.$socket.on('cmd', (msg) => {
        console.log('msg: ', msg);
        if (!msg) return false;
        if (msg.action === 'check' && msg.channel === 'version') {
          if (msg.data?.version === this.$store.getters.SocketVersion) return false;
          if (!this.$store.getters.SocketVersion) {
            // 首次登入直接覆寫
            this.$store.dispatch('app/setSocketVersion', msg.data.version);
            return;
          }
          this.$store.dispatch('app/setSocketVersion', msg.data.version);
          MessageBox.alert(this.$t('Message.Msg.VersionUpdatedMsg'), this.$t('Message.Msg.VersionUpdated'), {
            confirmButtonText: this.$t('common.confirm'),
            callback: async (action) => {
              location.reload(true);
            }
          });
        } else if (msg.action === 'logout' && msg.channel === 'unauthorized') {
          console.log('server refuse connect');
          this.$socket.disconnect();
        } else if (msg.action === 'refresh' && msg.channel === 'wallet') {
          if (msg.data && msg.data.type === 'deposit') {
            this.$message(this.$t('member.deposit.deposit') + this.$t('common.success'));
            this.$store.dispatch('dailyMission/getHasReward');
            const amount = +msg.data.amount;
            if (amount && amount > 0) {
              this.$track('上分成功', {
                event_category: 'Deposit',
                event_label: '上分成功',
                value: amount
              });
            }
          } else {
            this.$message(this.$t('member.withdrawal.withdrawal') + this.$t('common.success'));
          }
          this.$store.dispatch('user/getWallet');
        } else if (msg.action === 'refresh' && msg.channel === 'diamond') {
          this.$store.commit('user/SET_DIAMONDAMOUNT', msg.data.amount);
        } else if (msg.action === 'accomplish' && msg.channel === 'mission') {
          this.$store.commit('user/SET_HASDAILYREWARD', true);
        } else if (msg.channel === 'promotionFinish') {
          this.$store.dispatch('wallet/getCheckPromotion');
          this.$store.dispatch('promotion/getPromotions');
        } else if (msg.channel === 'level') {
          this.$store.dispatch('user/getMemberInfo');
          this.$store.dispatch('promotion/getPromotions');
          this.$store.dispatch('dailyMission/getAllData');
        }
      });

      this.$socket.on('disconnect', (reason) => {
        if (reason === 'io client disconnect') {
          console.log(reason);
          return false;
        }
        console.log('socket disconnect', reason);
        // this.$socket.disconnect()
        return false;
      });

      this.$socket.on('reconnect', () => {
        console.log('socket reconnect');
        this.$socket.connect();
      });

      this.$socket.on('connect_error', (reason) => {
        console.log('Server error, reconnecting...');
        return true;
      });

      return this.$socket;
    },
    closeSocket() {
      if (this.$socket && this.$socket.connected) {
        this.$socket.close();
        this.$socket.off('login');
        this.$socket.off('disconnect');
        this.$socket.off('reconnect');
        this.$socket.off('ping');
        this.$socket.off('connect_error');
        this.$socket.off('cmd');
      }
      if (this.WebSocket.HeartsInterval !== null) {
        this.ClearSocketHearts();
      }
    },
    async checkMaintain() {
      // const res = await API.System.CheckMaintain()
      // if (res.ErrorCode === 0) {
      //   this.$store.dispatch('app/setIsMaintainence', false)
      // } else if (res.ErrorCode === 999999) {
      //   await this.$store.dispatch('user/setAgentCode', '')
      // }
      // return Promise.resolve(res.ErrorCode === 0 || res.ErrorCode === 900012)
      return Promise.resolve(true);
    },
    fetchData() {
      // if (!this.isQueryChecked) { // 等待query檢查完才執行
      //   setTimeout(() => {
      //     this.fetchData()
      //   }, 500)
      //   return
      // }
      // const { dispatch } = this.$store
      // dispatch('user/resetLoading')
      // dispatch('wallet/resetLoading')
      // dispatch('dailyMission/resetLoading')
      // dispatch('promotion/resetLoading')
      // dispatch('systemMail/resetLoading')
      // if (this.token) {
      //   dispatch('user/getAllData')
      //   dispatch('wallet/getAllData')
      //   dispatch('dailyMission/getAllData')
      //   dispatch('promotion/getAllData')
      //   dispatch('systemMail/getAllData')
      //   /* 預設五分鐘輪詢 */
      //   // this.timer = setInterval(() => {
      //   //   dispatch('user/getAllData')
      //   //   dispatch('wallet/getAllData')
      //   //   dispatch('dailyMission/getAllData')
      //   //   dispatch('promotion/getAllData')
      //   //   dispatch('systemMail/getAllData')
      //   // }, 1000 * 60 * 5)
      // } else {
      //   if (this.timer) clearInterval(this.timer)
      // }
    },
    createManifest() {
      const ac = this.$store.getters.AgentCode;
      const manifest = {
        ...Manifest,
        start_url: ac ? `${window.location.origin}/?ac=${ac}` : window.location.origin,
        scope: window.location.origin,
        icons: [
          ...Manifest.icons.map((item) => ({
            ...item,
            src: `${window.location.origin}${item.src}`
          }))
        ]
      };
      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      const manifestNode = document.createElement('link');
      manifestNode.rel = 'manifest';
      manifestNode.href = manifestURL;
      document.head.appendChild(manifestNode);
    },
    checkCrossDay() { // 30秒檢查一次時間 檢查到時間=UTC+0 00:01則重打每日任務觸發掛任務
      this.timer = setInterval(() => {
        const currentTime = this.moment().local().format('HH:mm'); // 當前當地時間
        if (currentTime === this.localTime) {
          this.$store.dispatch('dailyMission/getAllData');
        }
      }, 1000 * 30);
    },
    startHintTimer() {
      this.hintTimer = setInterval(() => {
        if (this.isContentLoaded && !this.isRotateShow && !this.isYoutubePlayerShow) {
          this.hintCountDown -= 1;
        }
        if (this.hintCountDown <= 0) {
          clearInterval(this.hintTimer);
        }
      }, 1000);
    },
    handleTutorialClose() {
      this.isIosTutorialShow = false;
      this.isSafariHintShow = false;
    }
  }
};
