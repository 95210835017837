import { MessageBox } from 'element-ui';
import { isEmpty } from 'lodash';
import API from '../../api';

export default {
  inject: ['moment',
    '_',
    'Precision'],
  name: 'Promotion',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => []
    }
  },
  data() {
    return {
      result: {
        itemObject: null
      },
      isLoading: false,
      PromotionList: [],
      RemainingFlow: 0,
      FlowPercentage: 0,
      TotalFlow: 0,
      activeTypeIdx: 0,
      activePromotionIndex: 0,
      activePromotionType: 0,
      activePromotionList: [],
      dialog: {
        promotionDetail: {
          name: 'promotionDetail',
          locale: 'promotionDetail',
          show: false,
          itemObject: null
        },
        finished: {
          name: 'finished',
          locale: this.$t('Promotion.Action.finish'),
          show: false,
          itemObject: null
        },
        specialOffer: {
          name: 'specialOffer',
          locale: 'SpecialOffer',
          show: false,
          itemObject: null
        }
      },
      isPromotionInfoShow: false,
      Authority: false,
      myInterval: null,
      countDownTimes: 0,
      missionType: 1
    }
  },
  computed: {
    activePromotion() {
      return (this.activePromotionList && this.activePromotionList[this.activePromotionIndex]) || {};
    },
    memberInfo() {
      return this.$store.getters.MemberInfo;
    },
    typeTitle() {
      switch (this.activePromotionType) {
        case '0':
          return this.$t('vue.common.doubleTurnover');
        case '1':
          return this.$t('common.promotion.promotion');
        case '2':
          return this.$t('common.promotion.referral');
        case '4':
          return this.$t('common.promotion.rebate');
        case '6':
          return this.$t('promotion.missionCenter.dailyMission');
        case '7':
          return this.$t('member.deposit.depositPromotion');
        case '8':
          return this.$t('member.promotion.lottery');
      }
    },
    promotionData() {
      return this.$store.getters['promotion/Promotions']?.data;
    }
  },
  watch: {
    activeIndex() {
      this.isInfoShow = false;
    },
    promotionData: {
      handler(val) {
        this.RemainingFlow = val.RemainingFlow
        this.TotalFlow = val.TotalFlow
        this.FlowPercentage = val.FlowPercentage > 0 ? val.FlowPercentage : 0
        this.Authority = val.Authority
        this.countDownTimes = val.SettleWaitTime
        this.missionType = val.MissionType
        if (this.countDownTimes > 0) {
          this.initTimer(this.countDownTimes);
        }
        const isPageDataEmpty = isEmpty(this.PromotionList);
        this.PromotionList = val.MissionClassGroup;
        if (isPageDataEmpty) { // 頁面初始資料是空的才執行
          if (this.itemObject && this.itemObject.type === 7) {
            let index = 0;
            const find = this.PromotionList.find((item, idx) => {
              const key = Object.keys(item)[0];
              if (Number(key) === 7) index = idx;
              return Number(key) === 7;
            });
            if (find) {
              this.handleIconClick(find, index);
            } else {
              this.handleIconClick(this.PromotionList[0], 0);
            }
          } else {
            this.handleIconClick(this.PromotionList[0], 0);
          }
        } else {
          const find = this.PromotionList.find((item, idx) => {
            const result = Object.keys(item)[0] === this.activePromotionType;
            if (Object.keys(item)[0] === this.activePromotionType) {
              this.activeTypeIdx = idx;
            }
            return result;
          });
          if (find) {
            this.activePromotionList = Object.values(find)[0];
          } else {
            this.handleIconClick(this.PromotionList[0], 0);
          }
        }
      },
      immediate: true,
      deep: false
    }
  },
  mounted() {
    this.$store.dispatch('promotion/getPromotions');
  },
  methods: {
    close() {
      if (this.isLoading) return;
      this.$emit('closeDialog', this.dialogName, this.result);
      this.$emit('update:show', false);
    },
    async dataBind() {
      this.isLoading = true;
      const result = await this.$store.dispatch('promotion/getPromotions').catch(() => {
        this.isLoading = false;
      });
      this.isLoading = false;
    },
    openDialog(entry, action) {
      switch (action) {
        case 'promotionDetail':
          this.dialog.promotionDetail.show = true;
          this.dialog.promotionDetail.itemObject = entry;
          break;
        case 'finished':
          this.dialog.finished.show = true;
          this.dialog.finished.itemObject = entry;
          break;
        case 'specialOffer':
          this.dialog.specialOffer.show = true;
          this.dialog.specialOffer.itemObject = entry;
          break;
        default:
          break;
      }
    },
    closeDialog(dialogName, result) {
      switch (dialogName) {
        case 'promotionDetail':
          this.dialog.promotionDetail.show = false;
          break;
        case 'finished':
          this.dialog.finished.show = false;
          if (result.itemObject) {
            this.onPromotionClearFlow(result.itemObject);
          }
          break;
        case 'specialOffer':
          this.dialog.specialOffer.show = false;
          if (result.itemObject) {
            this.result.itemObject = result.itemObject;
            this.close();
          }
          break;
        default:
          break;
      }
    },
    async onPromotionJoin(obj) {
      // 彈出存款贈特例
      if (obj.MissionClass && obj.MissionClass === 7) {
        this.$store.commit('app/SET_ISLOADING', true);
        const { Data, ErrorCode } = await API.Financial.GetActivityDeposit({ PromotionGroupID: obj.PromotionGroupID });
        this.$store.commit('app/SET_ISLOADING', false);
        if (ErrorCode === 0) {
          this.result.itemObject = {
            action: 'openDialog',
            from: 'SpecialOffer',
            to: 'Deposit',
            itemData: {
              GroupID: obj.PromotionGroupID,
              PromotionList: Data.Mission
            }
          }
          this.close()
        }
        return;
      }
      if (obj.MissionClass && obj.MissionClass === 8) {
        this.result.itemObject = {
          action: 'openDialog',
          from: 'promotion',
          to: 'lottery'
        };
        this.close();
        return;
      }
      let formData = {
        MissionClass: obj.MissionClass,
        ID: obj.ID
      };
      this.$store.commit('app/SET_ISLOADING', true);
      const result = await API.Activity.Join_Promotion(formData).catch(() => {
        console.log('error');
      });
      this.$store.commit('app/SET_ISLOADING', false);
      if (result.ErrorCode === 0) {
        this.$track('參加活動', {
          event_category: 'click',
          event_label: '參加活動',
          value: obj.ID
        });
        this.$message.success({
          message: this.$t('Has Joined Promotion')
        });
        await this.dataBind();
      }
    },
    async onPromotionClearFlow(obj) {
      if (this.countDownTimes > 0) return;
      MessageBox.confirm(this.$t('promotion.template.messsage.finish'), '', {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('vue.common.cancel'),
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false
      })
        .then(async () => {
          let formData = {
            MissionClass: obj.MissionClass,
            ID: obj.ID
          };
          this.$store.commit('app/SET_ISLOADING', true);
          const result = await API.Activity.Finish_Promotion(formData).catch(() => {
            console.log('error');
          });
          this.$store.commit('app/SET_ISLOADING', false);

          if (result.ErrorCode === 0) {
            this.$message.success({
              message: this.$t('Promotion Finished')
            });
            await this.dataBind();
          } else if (result.Data.SettleWaitTime) {
            this.countDownTimes = result.Data.SettleWaitTime;
            this.initTimer(this.countDownTimes);
          }
        })
        .catch(() => {
          console.log('cancel');
        });
    },
    handleIcon(item) {
      if ([7, 8].includes(item.MissionClass)) return item.ImageUrl;
      if (item.MissionClass === 0) return require('@/assets/images/common/cash-flow-icon.webp');
      if (item.IconUrl) return item.IconUrl;
      switch (item.MissionClass) {
        case 0:
          return require('@/assets/images/common/cash-flow-icon.webp');
        case 2:
          return require('@/assets/images/common/bring-new-icon.webp');
        case 4:
          return require('@/assets/images/common/rack-back-icon.webp');
        case 6:
          return require('@/assets/images/common/daily-mission-icon.webp');
        default:
          return item.IconUrl;
      }
    },
    handleImage(item) {
      if (item.ImageUrl) return item.ImageUrl;
      switch (item.MissionClass) {
        case 0:
          return require('@/assets/images/common/cash-flow-image.webp');
        case 2:
          return require('@/assets/images/common/bring-new-image.webp');
        case 4:
          return require('@/assets/images/common/rack-back-image.webp');
        case 6:
          return require('@/assets/images/common/daily-mission-image.webp');
      }
      return item.IconUrl;
    },
    moneyFilter(num) {
      return this.$options.filters.toThousandFilter(num, 2);
    },
    handleBindInfo() {
      this.result.itemObject = {
        action: 'openDialog',
        from: 'promotion',
        to: 'DetailInfo'
      };
      this.close();
    },
    handleDotClick(i) {
      this.activePromotionIndex = i;
      this.isPromotionInfoShow = false;
      const element = this.$refs[`slider${i}`][0];
      const rect = element.getBoundingClientRect();
      const elementCanSee = rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      if (!elementCanSee) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },
    initTimer(reloadTime = 120) {
      reloadTime = Number(reloadTime.toFixed(0));
      if (this.myInterval) {
        clearInterval(this.myInterval);
      }
      this.myInterval = setInterval(() => {
        reloadTime--;
        this.countDownTimes = reloadTime;
        if (reloadTime === 0) {
          this.countDownTimes = 0;
          clearInterval(this.myInterval);
        }
      }, 1000);
    },
    handleIconClass(item, idx) {
      const canFinish = Object.values(item)[0].some((el) => el.FinishButton) && idx !== this.activeTypeIdx;
      return { active: idx === this.activeTypeIdx, canFinish };
    },
    handleIconSrc(item, idx) {
      const type = Object.keys(item)[0];
      switch (type) {
        case '0':
          return require(`@/assets/images/promotion/1x-wager${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '1':
          return require(`@/assets/images/promotion/promotion${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '2':
          return require(`@/assets/images/promotion/ref${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '4':
          return require(`@/assets/images/promotion/water-return${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '6':
          return require(`@/assets/images/promotion/daily-mission${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '7':
          return require(`@/assets/images/promotion/deposit${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
        case '8':
          return require(`@/assets/images/promotion/lottery${idx === this.activeTypeIdx ? '-active' : ''}.webp`);
      }
    },
    handleIconClick(item, idx) {
      this.activeTypeIdx = idx;
      this.activePromotionList = Object.values(item)[0];
      this.activePromotionType = Object.keys(item)[0];
      this.activePromotionIndex = 0;
      this.isPromotionInfoShow = false;
      this.$nextTick(() => {
        this.$refs.sliderBox.scrollTop = 0;
      });
    },
    handleSliderClick(idx) {
      this.activePromotionIndex = idx;
      this.isPromotionInfoShow = false;
    },
    handleTypeTitle(item) {
      const promotion = Object.keys(item)[0];
      switch (String(promotion)) {
        case '0':
          return this.$t('vue.common.doubleTurnover');
        case '1':
          return this.$t('common.promotion.promotion');
        case '2':
          return this.$t('common.promotion.referral');
        case '4':
          return this.$t('common.promotion.rebate');
        case '6':
          return this.$t('vue.common.daily');
        case '7':
          return this.$t('member.deposit.depositPromotion');
        case '8':
          return this.$t('member.promotion.lottery');
      }
    }
  }
};
