import otpVertify from './otp-vertify';
// import login from './login';
import lobby from './lobby';
import detailInfo from './detail-info';
import setting from './setting';
import app from './app';
import contentBox from './content-box';
import betRecord from './bet-record';
import promotion from './promotion';

export { default as otpVertify } from './otp-vertify';
// export { default as login } from './login';
export { default as lobby } from './lobby';
export { default as detailInfo } from './detail-info';
export { default as setting } from './setting';
export { default as app } from './app';
// export { default as contentBox } from './content-box';
export { default as betRecord } from './bet-record';
export { default as promotion } from './promotion';

export default {
  otpVertify,
  // login,
  lobby,
  detailInfo,
  setting,
  app,
  // contentBox,
  betRecord,
  promotion,
};
