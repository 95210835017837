import { mapGetters } from 'vuex';
import { MessageBox } from 'element-ui';
import common from '~/utils/common';
import { getMobile } from '../../utils';

export default {
  inject: ['_',
    'BGMPlay',
    'BGMPause',
    'playSound',
    'checkUserNotificationIsEnabled'],
  name: 'Setting',
  props: {
    title: {
      type: String,
      default: '',
    },
    dialogName: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    itemObject: {
      type: Object || Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('member', ['memberData',
      'isGuest',
      'snsBindList']),
    ...mapGetters('initData', ['isGCMSSyncEnable', 'getSystemConfig']),
    isLoading() {
      return this.$store.getters.isMemberInfoLoading;
    },
    appVersion() {
      return this.$store.getters.Version;
    },
    musicEnable() {
      return this.$store.state.app.isBGMEnable;
    },
    langOptions() {
      return this.$store.state.initData.languageList.map((lang) => ({
        value: lang.lang,
        label: lang.name,
      }));
    },
    MemberInfoData() {
      return this.$store.getters.MemberInfo;
    },
    gcmsMemberId() {
      return this.memberData.gcmsMemberId?.value || '';
    },
    deleteAccountUrl() {
      return this.getSystemConfig('SYSTEM_DELETE_ACCOUNT')?.link;
    },
  },
  data() {
    return {
      result: {
        itemObject: null,
      },
      langData: [],
      dialog: {
        changePassword: {
          name: 'changePassword',
          locale: 'changePassword',
          show: false,
          itemObject: null,
        },
        changePinCode: {
          name: 'changePinCode',
          locale: 'changePinCode',
          show: false,
          itemObject: null,
        },
      },
      canSubScribe: false,
    };
  },
  async mounted() {
    this.$nextTick(async () => {
      this.getMemberInfo();
      const isPushSupported = window.OneSignal?.isPushNotificationsSupported();
      if (!isPushSupported) return;
      this.getSubscriptionState()
        .then((state) => {
          console.log('getSubscriptionState', state);
          this.canSubScribe = state ? (!state.isPushEnabled || state.isOptedOut) : false;
          console.log('canSubScribe', this.canSubScribe);
        });
      window.OneSignal?.on('subscriptionChange', (isSubscribed) => {
        console.log('subscriptionChange, isSubscribed:', isSubscribed);
        if (isSubscribed) {
          this.canSubScribe = false;
        }
      });
    });
  },
  methods: {
    close() {
      if (this.isLoading) return;
      this.langShow = false;
      this.$emit('closeDialog', this.dialogName, this.result);
      this.$emit('update:show', false);
      this.playSound('close');
    },
    openDialog(entry, action) {
      this.playSound('open');
      switch (action) {
        case 'memberInfo':
          this.$track('打開會員資訊頁', {
            event_category: 'click',
            event_label: '打開會員資訊頁',
          });
          this.dialog.memberInfo.show = true;
          this.dialog.memberInfo.itemObject = entry;
          break;
        case 'changePassword':
          this.$track('打開修改密碼頁', {
            event_category: 'click',
            event_label: '打開修改密碼頁',
          });
          this.dialog.changePassword.show = true;
          break;
        case 'referrer':
          this.dialog.referrer.show = true;
          break;
        case 'bindAccount':
          this.$track('打開綁定帳號頁', {
            event_category: 'click',
            event_label: '打開綁定帳號頁',
          });
          this.dialog.bindAccount.show = true;
          break;
        case 'Invite':
          this.$track('打開邀請連結頁', {
            event_category: 'click',
            event_label: '打開邀請連結頁',
          });
          this.dialog.Invite.show = true;
          break;
        case 'changePinCode':
          this.$track('打開修改Pin Code', {
            event_category: 'click',
            event_label: '打開修改Pin Code',
          });
          this.dialog.changePinCode.show = true;
          break;
        case 'confirm':
          this.langShow = false;
          this.dialog.confirm.show = true;
          this.dialog.confirm.itemObject = {
            action: 'reload',
            From: 'Confirm',
            To: 'Setting',
            data: entry,
          };
          break;
      }
    },
    closeDialog(dialogName, result) {
      switch (dialogName) {
        case 'memberInfo':
          this.getMemberInfo();
          break;
        case 'changePassword':
          break;
        case 'referrer':
          this.getMemberInfo();
          break;
        case 'bindAccount':
          this.getMemberInfo();
          break;
        case 'Invite':
          break;
        case 'confirm':
          this.dialog.confirm.show = false;
          if (result.itemObject && result.itemObject.action === 'reload') {
            this.changeLanguage(result.itemObject.data.Code);
            this.$track('語系切換', {
              event_category: 'click',
              event_label: '語系切換',
              value: result.itemObject.data.Code,
            });
            window.location.reload(true);
          }
          break;
      }
    },
    async logout() {
      // 訪客登出提示
      if (this.isGuest && this.snsBindList.length === 0) {
        const confirm = await MessageBox.confirm(this.$t('member.guest.hint.logout'), '', {
          confirmButtonText: this.$t('common.system.buttom.logout'),
          cancelButtonText: this.$t('vue.common.cancel'),
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
        })
          .then(() => true)
          .catch(() => false);

        if (!confirm) return;
      }

      // 沒綁定帳號時跳提示
      // if (!this.MemberInfoData.AccountBind && !this.MemberInfoData.FbBind) {
      //   const confirm = await MessageBox.confirm(this.$t('common.errored.account.bind'), '', {
      //     confirmButtonText: this.$t('member.account.bind'),
      //     cancelButtonText: this.$t('common.system.buttom.logout'),
      //     showClose: false,
      //     closeOnClickModal: false,
      //     closeOnPressEscape: false,
      //   })
      //     .then(() => true)
      //     .catch(() => false);

      //   if (confirm) {
      //     this.openDialog(null, 'bindAccount');
      //     return;
      //   }
      // }

      this.logoutAction();
    },
    async logoutAction() {
      this.$track('登出', {
        event_category: 'click',
        event_label: '登出',
      });
      // FB Logout
      // this.faceBookLogout();
      const res = await this.$store.dispatch('logoutRequest');
      if (res?.isSuccess) {
        this.$store.dispatch('logout');
        this.$store.dispatch('financial/actionResetWallet');
        common.removeLocalStorageKey('Authorization');
        common.removeLocalStorageKey('RefreshToken');
        window.location.href = '/';
      }
    },
    changeLanguage(langCode) {
      if (langCode === this.$store.getters.language) return;

      this.$i18n.locale = langCode;
      this.$store.dispatch('app/setLanguage', langCode);
      this.$store.dispatch('initData/getInitData');
      this.$store.dispatch('chat/actionGetBonusNotify');
    },
    async getMemberInfo() {
      // await this.$store.dispatch('user/getMemberInfo');
    },
    faceBookLogout() {
      // 檢查登入狀態
      window.FB?.getLoginStatus((response) => {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB?.logout((response) => {
            console.log(response);
          });
          // })
        } else {
          // do something
          console.log('logout connected else something');
        }
      });
    },
    handleMusicChange(v) {
      this.$store.dispatch('app/setBGMEnable', v);
      this.$track('設定BGM', {
        event_category: 'click',
        event_label: '設定BGM',
        value: v,
      });
      v ? this.BGMPlay() : this.BGMPause();
    },
    async getSubscriptionState() {
      return Promise.all([
        window.OneSignal?.isPushNotificationsEnabled(), window.OneSignal?.isOptedOut(),
      ]).then((result) => {
        let isPushEnabled = result[0];
        let isOptedOut = result[1];
        console.log({
          isPushEnabled,
          isOptedOut,
        });
        return {
          isPushEnabled,
          isOptedOut,
        };
      });
    },
    handleSubscribe() {
      this.$track('訂閱通知', {
        event_category: 'click',
        event_label: '訂閱通知',
      });
      this.getSubscriptionState().then((state) => {
        if (state.isPushEnabled) {
          /* Subscribed, opt them out */
          // window.OneSignal.setSubscription(false)
          this.canSubScribe = false;
        } else if (state.isOptedOut) {
          /* Opted out, opt them back in */
          window.OneSignal?.setSubscription(true);
        } else {
          /* Unsubscribed, subscribe them */
          window.OneSignal?.registerForPushNotifications();
        }
      });
    },
    openPromoCode() {
      this.result = {
        from: 'setting',
        to: 'promocode',
        action: 'openDialog',
      };
      this.close();
    },
    handleOpenTerm() {
      this.playSound();
      this.$router.push({
        name: 'Terms',
      });
    },
    deleteAccount() {
      const page = window.open(this.deleteAccountUrl);
    },
  },
};
